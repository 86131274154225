import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#5bc888',
          secondary: '#00A39B',
          theme: '#267B8F',
          darktheme: '#425466',
          dark: '#353535',
          light:'00A39B',
          error:'C34B4B',
          warning:'FFC259',
          admin:'E9E916',
          required:'AB49AB',
          third:'52D6CD',
          
          
        },
      },
    },
  })

export default new Vuetify({
   
});




